@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Lalezar|Montserrat');

$primary: #00ff00;/* MAIN COLOR */
$secondary: #00ffff; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-size: 1rem;
}

h1 {  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background: #333;

    .navbar-nav {
        >li>a {
            font-family: 'Montserrat', sans-serif;
            font-size: 18px;
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $wht;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
                text-align: center;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $wht;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $wht;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 0.5em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}
.modal-dialog {
    max-width: 500px;
    width: 100%;
    text-align: center;
    margin: 6em auto;

    .close {display: none;}

    .modal-content {
        color: $primary;

        h2 {
            text-align:center;
            font-size: 25px;
        }
    }

    a.btn, a.btn-default, button {
        @include btn
        margin: 0;
        display: block;
    }
}

.modal-header, .modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}



/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $blk;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 350px;
    padding: 0.5em;
}

@media(max-width: 991px) {
    .logo {
        max-width: 315px;
    }
    .navbar-header a {
        float: left;
    }
}

@media(max-width: 767px) {
    .logo {
        max-width: 265px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 225px;
    }
    .navbar-toggle {
        margin-top: 15px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
    p {
        font-size: 1rem;
    }
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.head {
    background: linear-gradient(
        rgba(0,0,0,0.5),
        rgba(0,0,0,0.7)
        ), url('../img/img1.jpg');
    background-size: cover;
    padding: 200px 0;
    background-attachment: fixed;

    @media (max-width: 1024px) {
        background-attachment: scroll;
    }
}

.align {
    display: block;
    margin: 0 auto;
}

.headline {
    font-family: 'Lalezar', sans-serif;
    text-align: center;
    color: $secondary;
    @include fluid-type($min_width, $max_width, 50px, 70px);
}

.tagline {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-transform: uppercase;
    margin-top: -15px;
    color: $wht
}

i {
    font-size: 60px;
    color: darken($primary,10%);
}

.pad {
    padding: 30px 0;
}

.serviceTitle {
    font-family: 'Lalezar', sans-serif;
    text-align: center;
    @include fluid-type($min_width, $max_width, 20px, 30px);
    margin-bottom: 0;
}

.serviceTxt {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    @include fluid-type($min_width, $max_width, 13px, 16px);
}

.smallAbout {
    font-family: 'Montserrat', sans-serif;
    margin-bottom: -20px;
    @include fluid-type($min_width, $max_width, 15px, 17px);
}

.smallAbout2 {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    margin-bottom: -18px;
    @include fluid-type($min_width, $max_width, 15px, 17px);
}

.aboutTitle {
    font-family: 'Lalezar', sans-serif;
    @include fluid-type($min_width, $max_width, 30px, 50px);
    margin-bottom: 0;
}

.aboutTxt {
    font-family: 'Montserrat', sans-serif;
    @include fluid-type($min_width, $max_width, 16px, 20px);
}

.article-block {
    margin-top: 15px;
    text-align: center;
    width: 24%;
    margin-left: 5px;
    padding: 45px;
    border: 1px solid #ececec;
    border-radius: 10px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;

    @media (max-width: 991px) {
        width: 96%;
    }
}

.Ssect {
    font-family: 'Lalezar', sans-serif;
    text-align: center;
}

.imgCont {
    background: linear-gradient(
        rgba(0,0,0,0.5),
        rgba(0,0,0,0.7)
        ), url('../img/bg1.jpg');
    background-size: cover;
    padding: 150px 0;
    background-attachment: fixed;

    @media (max-width: 1024px) {
        background-attachment: scroll;
    }
}

.statement {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    color: $wht;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.contact-form .form-control {
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    color: #555;
    display: block;
    font-size: 16px;
    height: 50px;
    letter-spacing: 1px;
    line-height: 1.42857;
    padding-bottom: 6px;
    padding-left: 30px;
    padding-top: 6px;
    position: relative;
    text-transform: capitalize;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
}
.contact-form .form-control:focus {
    box-shadow: none;
    border-color: #0061ff;
    transition: .3s;
}
.contact-form form textarea.form-control {
    height: 180px;
    padding-top: 15px;
    resize: none;
    width: 100%;
}
button.contact-btn {
    background: #0061ff none repeat scroll 0 0;
    border: medium none;
    border-radius: 4px;
    box-shadow: none;
    color: #fff;
    padding: 10px 20px;
    text-transform: capitalize;
}
span.c-icon {
    font-size: 12px;
    left: 25px;
    position: absolute;
    top: 12px;
    color: #666;
}
.s-address-icon {
    background: $secondary none repeat scroll 0 0;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 22px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    width: 50px;

    @media (max-width: 990px) {
        margin-top: 7px;
        line-height: 35px;
        height: 35px;
        width: 35px;
        font-size: 18px;
    }

    @media (max-width: 550px) {
        width: 23px;
        height: 23px;
        font-size: 14px;
        margin-top: 12px;
        line-height: 24px;
    }
}
.single-address > p {
    font-family: 'Catamaran', sans-serif !important;
    margin-bottom: 0;
    font-size: 13px;
    margin-left: 10px;
    display: inline-block;
    line-height: 50px;
    color: #333;

    @media (max-width: 550px) {
        font-size: 12px;
    }
}
.single-address2 > p {
    font-family: 'Catamaran', sans-serif !important;
    margin-bottom: 0;
    font-size: 13px;
    margin-left: 10px;
    display: inline-block;
    line-height: 50px;
    color: #333;

    @media (max-width: 550px) {
        font-size: 10px;
    }
}
.single-address {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    overflow: hidden;
    padding: 5px;
}

.single-address2 {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    overflow: hidden;
    padding: 5px;
}

h1{
    margin-bottom: 40px;
}
label {
    color: #333;
}
.btn-send {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 20px;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    -webkit-box-shadow: none;
}

.g-recaptcha {
    transform:scale(0.86);
    transform-origin:0 0;
}